import React, { useState } from 'react'
import imgFw from '../../images/nara-logo.gif'
import imgBw from '../../images/nara-logo-reverse.gif'

const LogoGif = props => {
  const [isShown, setIsShown] = useState(false);
  return (<>
    <img
      style={{height: 0}}
      alt={'najlepsie logo'}
      src={imgFw}/>
      <img
      style={{height: 0}}
      alt={'najlepsie logo'}
      src={imgBw}/>
    <img
      style={{maxHeight: 80}}
      alt={'najlepsie logo'}
      src={isShown? imgFw : imgBw}
      onMouseEnter={() => setIsShown(true)}
      onMouseLeave={() => setIsShown(false)}/>
  </>)}

LogoGif.propTypes = {

}

export default LogoGif
